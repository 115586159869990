<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex">
      <button v-if="showBackButton()"
          class="btn btn-light bi bi-chevron-left btn-sm me-3"
          type="button"
          @click="$router.go(-1)"
        ></button>
      <span class="navbar-brand mb-0 h1">{{title ?? 'Outbound'}} </span>
      </div>
      <!-- {{ $route }} -->
      <form class="d-flex">
        <button
          class="btn btn-success bi bi-check-lg me-2"
          type="button"
          @click="$emit('save')"
        >
          Sačuvaj
        </button>
        <button
          v-if="$route.name == 'selling' && status != 'New'"
          class="btn btn-warning bi bi-ui-checks-grid me-2"
          type="button"
          @click="$router.push({ name: 'selling-form', params: { id: $route.params.id } })"
        >
          Zapisnik
        </button>
        <button
          v-if="$route.name == 'selling-form'"
          class="btn btn-warning bi bi-car-front me-2"
          type="button"
          @click="$router.push({ name: 'selling', params: { id: $route.params.id } })"
        >
          Prodaja
        </button>
        <button
          v-if="status == 'Done' && $route.params.type == 'Proform'"
          class="btn btn-success bi bi-journal-plus me-2"
          type="button"
          @click="$emit('newAdvance')"
        >
          Kreiraj Avans
        </button>
        <button
          v-if="status == 'Done' && $route.params.type != 'Normal'"
          class="btn btn-success bi bi-journal-plus me-2"
          type="button"
          @click="$emit('newNormal')"
        >
          Kreiraj Račun
        </button>
        <button
          class="btn btn-danger bi bi-x-lg"
          type="button"
          @click="goBack()"
        >
          Nazad
        </button>
        <button
          v-if="status != 'New'"
          class="btn btn-danger bi bi-trash ms-2"
          type="button"
          @click="$emit('delete')"
        ></button>
      </form>
    </div>
  </nav>
</template>

<script>
export default {
  name: "reservation-header",
  components: {
    // InvoiceStatus,
  },
  props: {
    status: {
      type: String,
      required: true,
      default: "New",
    },
    title: {
      type: String,
      required: false,
    },
  },
  methods: {
    ///check history count and go back or close window
    goBack() {  
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        // @grant        window.close
        window.close();
      }
    },
    showBackButton() {
      return window.history.length > 1;
    },
  },
};
</script>

<style scoped>
</style>
