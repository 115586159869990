<template>
  <div class="form-item">
    <v-select
      :options="data"
      :label="labelfield ?? 'name'"
      :multiple="multiple"
      :disabled="disabled"
      v-model="selected"
      @option:selected="(val) => handleInput(val)"
      :clearable="clearable ?? false"
      :style="
        dropdownHeight ? `--vs-dropdown-max-height: ${dropdownHeight}` : ''
      "
      @option:deselected="(val) => handleInput(val)"
    />
  </div>
</template>

<script>
import http from "../../utils/http-common";

export default {
  name: "dropdown",
  props: [
    "url",
    "label",
    "value",
    "list",
    "empty",
    "labelfield",
    "dropdownHeight",
    "multiple",
    "disabled",
    'clearable'
  ],
  data() {
    return {
      data: [],
      selected: {},
    };
  },
  methods: {
    async fetchData() {
      const res = await http.get(this.url);
      this.data = this.empty
        ? res.data.rows ? [{ id: 0, name: "-" }, ...res.data.rows] : [{ id: 0, name: "-" }, ...res.data]
        : res.data.rows ? res.data.rows : res.data;
      if (this.multiple) {
        this.selected = this.value;
      } else {
        this.selected = this.data.find((i) => i.id == this.value);
      }
    },
    handleInput(val) {
      if (this.multiple) {
        // console.log(this.selected);
        this.$emit(
          "selected",
          this.selected.map((v) => v.id)
        );
      } else {
        this.$emit("selected", val.id);
      }
    },
  },
  computed: {},
  mounted() {
    if (this.list != null) {
      this.data = this.list;
      this.selected = this.data.find((i) => i.id == this.value);
    } else {
      this.fetchData();
    }
  },
  watch: {
  selected(value) {
    if (value === null) { // or !value if using other falsy values
      this.$emit("selected", null);
    }
  }
}
};
</script>

<style scoped>
.form-item {
  /* --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee; */
  --vs-dropdown-z-index: 10000;

  /* --vs-dropdown-max-height: 150px; */
}
</style>